import { GetParkingSessionPermitEventData } from '@contracts/types/GetParkingSessionData'
import { GetPermitResultForSession } from '@contracts/types/GetPermitResultForSession'
import { Period } from '@contracts/types/Period'

import { getPeriodForParking, isPeriodValid } from './PermitPeriodHelper'

export function isValidPermitTime({ permit, now, site }: GetParkingSessionPermitEventData): GetPermitResultForSession {
  const { validityPeriods = [] } = permit

  // TODO WRITE TEST, if no validityPeriods, return true
  if (validityPeriods.length === 0) return { result: true, messages: [], permit }

  const period: Period = getPeriodForParking({ now, site })

  const result: GetPermitResultForSession = { result: true, messages: [], permit }
  result.result = validityPeriods.some((validityPeriod) => isPeriodValid({ period, validityPeriod }))

  if (!result.result) result.messages.push(`Cant find a valid period, now: ${now || ''}, permitId: ${permit.id}`)

  return result
}
