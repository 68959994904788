import styled from '@emotion/styled'
import Drawer from '@mui/material/Drawer'

import { Spacings } from '@contracts/enums/Spacings'
import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { Site } from '@contracts/types/Site'

import { Colors } from '@pure/assets/Colors'
import Images from '@pure/assets/PayImages'
import Texts from '@pure/assets/PayTexts'
import { getFigmaTextFromVariable } from '@pure/libs/TextRepository'
import { formatText } from '@pure/libs/TextRepositoryHelper'

import { useCustomPricing } from '@web-js/hooks/useCustomPricing'

import { useIsDesktop } from '@pay/hooks/useIsMobile'
import { db } from '@pay/libs/FirebaseOptions'
import { Variables } from '@pay/libs/Variables'

import Box from './Box'
import CustomPricing from './CustomPricing'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { LAYOUT_CHILDREN_MAX_WIDTH, ONE_PIXEL } from './HardcodedSizes'
import PricingTable from './PricingTable'

type Props = { open: boolean; onClose: () => void; site?: Site }

export function StationInfoDrawer(props: Props) {
  const isDesktop = useIsDesktop()
  const parkingSegments = props.site?.segments

  const width = isDesktop ? `${LAYOUT_CHILDREN_MAX_WIDTH}px` : '100%'

  const { data: customPricing } = useCustomPricing(db, props.site?.id)

  const getOffenseText = (offenseName?: string) => {
    switch (offenseName) {
      case 'CHARGE_SLOT_OFFENSE':
        return getFigmaTextFromVariable(Variables['VariableID:4479:22338'])
      case 'DISABLED_SLOT':
        return getFigmaTextFromVariable(Variables['VariableID:4479:22342'])
      case 'QUICKSTOP_OVERSTAY':
        return getFigmaTextFromVariable(Variables['VariableID:4479:22339'])
      case 'MARKED_SLOT':
        return getFigmaTextFromVariable(Variables['VariableID:4479:22341'])
      case 'VEHICLE_POSITION':
        return getFigmaTextFromVariable(Variables['VariableID:4479:22340'])
    }
  }

  return (
    <Drawer anchor="bottom" hideBackdrop {...props}>
      <Box width={width} fullPadding alignSelf="center">
        <Box fullWidth direction="row" align="center" justify="space-between" gap={Spacings.xs}>
          <FigmaImageContainer imageKey={Images.infoIcon} />
          <Box fullWidth>
            <FigmaText
              textKey={Texts.textActiveSessionParkingInformationCssInformationLocation}
              text={props?.site?.name}
            />
            <FigmaText textKey={Texts.textActiveSessionParkingInformationInformationScreenHeader} />
          </Box>
          <CloseButton align="center" fullPadding spacing={Spacings.xs} onClick={() => props.onClose()} pointer>
            <FigmaText textKey={Texts.textButtonsTxtButtonClose} />
          </CloseButton>
        </Box>
        <Box fullWidth direction={isDesktop ? 'row' : 'column'} gap={Spacings.m}>
          {props?.site && (customPricing?.items.length ?? 0) > 0 ? (
            <CustomPricing site={customPricing} />
          ) : (
            parkingSegments?.map((segment, index) => {
              return (
                <Box key={index} fullWidth>
                  <SegmentTitle fullWidth fullPadding spacing={Spacings.s}>
                    <FigmaText
                      textKey={Texts.textActiveSessionParkingInformationInformationScreenHeader}
                      text={segment.name}
                    />
                  </SegmentTitle>
                  <Box key={index} fullWidth bottom spacing={Spacings.xs} gap={Spacings.xxs}>
                    {segment?.pricing?.parking?.type !== 'absolute' && (
                      <Box fullWidth bottom spacing={Spacings.s}>
                        <FigmaText
                          textKey={Texts.textActiveSessionParkingInformationInformationScreenMainInfo}
                          text={formatText(
                            getFigmaTextFromVariable(Variables['VariableID:4479:20046']),
                            [segment?.pricing?.parking?.freeDurationMinutes],
                            {
                              splitCharacter: 'xxx'
                            }
                          )}
                        />
                      </Box>
                    )}
                    {segment?.pricing?.parking?.type == 'absolute' &&
                      segment?.pricing?.parking?.dailyFreeDurationMinutes && (
                        <Box fullWidth bottom spacing={Spacings.s}>
                          <FigmaText
                            textKey={Texts.textActiveSessionParkingInformationInformationScreenMainInfo}
                            text={formatText(
                              getFigmaTextFromVariable(Variables['VariableID:4479:20046']),
                              [segment?.pricing?.parking?.dailyFreeDurationMinutes],
                              {
                                splitCharacter: 'xxx'
                              }
                            )}
                          />
                        </Box>
                      )}

                    <Box fullWidth bottom spacing={Spacings.s}>
                      <FigmaText
                        textKey={Texts.textActiveSessionParkingInformationInformationCssFeeValue}
                        text={getFigmaTextFromVariable(Variables['VariableID:648:7434'])}
                      />
                      <PricingTable item={segment?.pricing?.parking?.items} type={segment?.pricing?.parking?.type} />
                    </Box>
                    {segment?.pricing?.offense && segment?.pricing?.offense?.length > 0 && (
                      <FigmaText
                        textKey={Texts.textActiveSessionParkingInformationInformationCssFeeValue}
                        text={getFigmaTextFromVariable(Variables['VariableID:4479:20048'])}
                      />
                    )}

                    {segment?.pricing?.offense?.map((item, index) => {
                      return (
                        <Box key={index} fullWidth top bottom spacing={Spacings.xs}>
                          <OffenseTitle fullWidth fullPadding spacing={Spacings.xxs}>
                            <FigmaText
                              textKey={Texts.textActiveSessionParkingInformationInformationScreenSection1Header}
                              text={getOffenseText(item?.offenseType?.toUpperCase())}
                            />
                          </OffenseTitle>
                          <PricingTable isOffense item={item} type={item?.type} />
                        </Box>
                      )
                    })}
                  </Box>
                </Box>
              )
            })
          )}
        </Box>
      </Box>
    </Drawer>
  )
}

const CloseButton = styled(Box)`
  border: ${ONE_PIXEL} solid ${Colors.black};
  border-radius: ${BorderRadiusesPx.rounded};

  :hover {
    box-shadow: 0 0 5px ${Colors.black};
  }
`

const SegmentTitle = styled(Box)`
  margin-top: ${Spacings.m};
  margin-bottom: ${Spacings.s};
  background-color: ${Colors.pink1};

  span {
    color: #091e42 !important;
  }
`

const OffenseTitle = styled(Box)`
  background: ${Colors.yellow1};
  margin-bottom: ${Spacings.xs};
`
