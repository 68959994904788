export const HardCodedTexts = {
  textPaymentsSwishEnterPhoneNumber: {
    characters: 'Enter phone number',
    name: 'textPaymentsSwishEnterPhoneNumber',
    texts: {
      swe: 'Ange telefonnummer',
      eng: 'Enter phone number'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(53, 71, 100, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textUnpayedSessionPrice: {
    characters: 'kr',
    name: 'textUnpayedSessionPrice',
    texts: {
      swe: 'kr',
      eng: 'kr'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '22px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(53, 51, 85, 1)'
    },
    pageName: 'Payment Flows QR'
  },
  textGuestParkingNoGuestPermitsForSite: {
    characters: 'There is no guest parking permit available for this site.',
    name: 'textGuestParkingNoGuestPermitsForSite',
    texts: {
      swe: 'There finns inget gästparkeringsstillstånd tillgängligt för denna plats.',
      eng: 'There is no guest parking permit available for this site.'
    },
    style: {
      fontFamily: 'Inter',
      fontSize: '22px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(53, 71, 100, 1)'
    },
    pageName: 'Guest Feature'
  },
  textGuestParkingPromptTermsFirstHalf: {
    characters: 'In order to proceed with free guest parking, you must accept our ',
    name: 'textGuestParkingPromptConditionsFirstHalf',
    texts: {
      swe: 'För att kunna gå vidare med gratis gästparkering måste du acceptera våra ',
      eng: 'In order to proceed with free guest parking, you must accept our '
    },
    style: {},
    pageName: 'Guest Feature'
  },
  textGuestParkingPromptTermsSecondHalf: {
    characters: 'Please ensure you have read and understood them before continuing.',
    name: 'textGuestParkingPromptConditionsSecondHalf',
    texts: {
      swe: ' Vänligen säkerställ att du har läst och förstått dem innan du fortsätter.',
      eng: ' Please ensure you have read and understood them before continuing.'
    },
    style: {},
    pageName: 'Guest Feature'
  },
  textGuestParkingPromptTermsLink: {
    characters: 'Terms and Conditions',
    name: 'textGuestParkingPromptTermsLink',
    texts: {
      swe: 'villkor.',
      eng: 'Terms and Conditions.'
    },
    style: {},
    pageName: 'Guest Feature'
  },

  textGuestParkingTermsAndConditionsHeadline: {
    characters: 'Terms and Conditions',
    name: 'textGuestParkingTermsAndConditionsHeadline',
    texts: {
      swe: 'Villkor',
      eng: 'Terms and Conditions'
    },
    style: {},
    pageName: 'Guest Feature'
  },
  textGuestParkingStartParking: {
    characters: 'Approve',
    name: 'textGuestParkingStartParking',
    texts: {
      swe: 'Godkänn',
      eng: 'Approve'
    },
    style: {},
    pageName: 'Guest Feature'
  },
  textGuestParkingDecline: {
    characters: 'Decline',
    name: 'textGuestParkingDecline',
    texts: {
      swe: 'Avvisa',
      eng: 'Decline'
    },
    style: {},
    pageName: 'Guest Feature'
  },
  textGuestParkingDeclineErrorInformation: {
    characters: 'Accept the terms to proceed with free guest parking. Please go back and try again.',
    name: 'textGuestParkingDeclineErrorInformation',
    texts: {
      swe: 'Acceptera villkoren för att fortsätta med gratis gästparkering. Vänligen gå tillbaka och försök igen.',
      eng: 'Accept the terms to proceed with free guest parking. Please go back and try again.'
    },
    style: {},
    pageName: 'Guest Feature'
  },
  textGuestParkingReturn: {
    characters: 'Back',
    name: 'textGuestParkingGoBack',
    texts: {
      swe: 'Tillbaka',
      eng: 'Back'
    },
    style: {},
    pageName: 'Guest Feature'
  },
  textGuestParkingPromptToCallSupport: {
    characters: 'Notice a problem?',
    name: 'textGuestParkingPromptToCallSupport',
    texts: {
      swe: 'Ser du ett problem?',
      eng: 'Notice a problem?'
    },
    style: {},
    pageName: 'Guest Feature'
  },
  textGuestParkingCallSupport: {
    characters: 'Contact Support.',
    name: 'textGuestParkingCallSupport',
    texts: {
      swe: 'Kontakta Support',
      eng: 'Contact Support'
    },
    style: {},
    pageName: 'Guest Feature'
  }
}
