enum Collections {
  USERS = 'users',
  DEBTORS_DEPRECATED = 'debtors',
  DEBTOR_SETTINGS = 'debtor_settings',
  SESSIONS = 'sessions',
  SESSIONS_FINANCIAL = 'sessions_financial',
  SESSIONS_FALSE = 'sessions_false',
  FORTNOX_CREDENTIALS = 'fortnox_credentials',
  FORTNOX_VOUCHERS = 'fortnox_vouchers',
  SITES = 'sites',
  PERMIT_TEMPLATES = 'permit_templates',
  PERMIT_USER_DELETION = 'permit_user_deletion',
  VEHICLES = 'vehicles',
  CAMERA_EVENTS = 'camera_events',
  IMAGE_LOG = 'image_log',
  SWISH_PAYMENT_INTENT_STATUS = 'swish_payment_intent_status',
  CAMERAS = 'cameras',
  COLLECTIVE_INVOICES = 'collective_invoices',
  VALITIVE_CREDENTIALS = 'valitive_credentials',
  BILLECTA_INVOICES = ' billecta_invoices',
  BILLECTA_PRODUCTS = ' billecta_products',
  COUNTERS = 'counters',
  SITE_STATISTICS = 'site_statistics',
  API_KEYS = 'api_keys',
  GUEST_PARKING_REQUESTS = 'guest_parking_requests',
  LIVE_SITE_MAP_STATS = 'live_site_map_stats',
  CUSTOM_PRICING = 'custom_pricing'
}

// CollectionGrous pollute global space, use reponsibly, see slack: https://drifterworldgroup.slack.com/archives/C06FACPRXTP/p1713322922909389
export enum CollectionGroups {
  SITE_CAMERA_EVENTS = 'site_camera_events',
  PERMITS = 'permits',
  PERMIT_DEBTORS = 'permit_debtors',
  USER_VEHICLES = 'user_vehicles',
  ACTIVE_SITE_CAMERA_EVENTS = 'active_site_camera_events',
  GUEST_PARKING_SESSIONS = 'guest_parking_sessions'
}

export default Collections
