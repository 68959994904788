import styled from '@emotion/styled'
import { Divider, IconButton } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { PropsWithChildren } from 'react'

import { BorderRadiusesPx } from '@pure/contracts/types/BorderRadixes'

import { Spacings } from '@guest-parking/enums/Spacings'
import HardCodedImages from '@guest-parking/libs/HardCodedImages'

import Box from './Box'
import FigmaImage from './FigmaImage'

interface BottomDrawerModalProps {
  open: boolean
  onClose: () => void
  headline?: string
}

const BottomDrawerModal = ({ open, onClose, children, headline }: PropsWithChildren<BottomDrawerModalProps>) => {
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderTopLeftRadius: BorderRadiusesPx.rounded,
          borderTopRightRadius: BorderRadiusesPx.rounded,
          maxHeight: '80vh'
        }
      }}
    >
      <Box fullWidth spacing={Spacings.s} fullPadding direction="row" justify="space-between" align="center">
        <StyledHeadlineText>{headline}</StyledHeadlineText>
        <IconButton size="small" aria-label="close" onClick={onClose}>
          <FigmaImage imageKey={HardCodedImages.closeIconRoundedNoBackground} />
        </IconButton>
      </Box>
      <Divider />
      {children}
    </Drawer>
  )
}

const StyledHeadlineText = styled.h3`
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: rgba(32, 32, 32, 1);
  margin: 0;
  line-height: 28px;
`

export default BottomDrawerModal
