import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isoWeek from 'dayjs/plugin/isoWeek'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(isoWeek)
dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)

export const DayJS = dayjs

export const sortDates = (a: dayjs.Dayjs, b: dayjs.Dayjs) => Math.floor(a.unix()) - Math.floor(b.unix())

export const threeDaysAgo = () => DayJS().subtract(3, 'day')

export const threeDaysIntoFuture = () => DayJS().add(3, 'day')

export const fiveMinutesAgo = () => DayJS().subtract(5, 'minute')

export const tenMinutesAgo = () => DayJS().subtract(10, 'minutes')

export const twoHoursAgo = () => DayJS().subtract(2, 'hours')

export const fiveMinutesIntoFuture = () => DayJS().add(5, 'minute')
