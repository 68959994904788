import styled from '@emotion/styled'
import { Button as MuiButton, DialogActions, DialogContent } from '@mui/material'
import { useEffect, useState } from 'react'

import { Spacings } from '@guest-parking/enums/Spacings'
import { useFreeGuestParkingTokenPayload } from '@guest-parking/hooks/useFreeGuestParkingToken'
import { useLanguage } from '@guest-parking/hooks/useLanguage'
import { getDrifterEulaHTML } from '@guest-parking/libs/CloudFunctionsApiHandler'
import { GuestParkingViewProps } from '@guest-parking/libs/GuestParkingHelper'
import { HardCodedTexts } from '@guest-parking/libs/HardCodedTexts'
import { useSite } from '@guest-parking/libs/ReactQueryHooks'
import { getFigmaText } from '@guest-parking/libs/TextRepository'
import Texts from '@guest-parking/libs/Texts'

import BottomDrawerModal from './BottomDrawerModal'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import { GuestParkingSiteLogo } from './GuestParkingSiteLogo'
import { Layout } from './Layout'

const DecisionButtons = ({
  onAccept,
  onDecline,
  isLoading
}: {
  onAccept: () => void
  onDecline: () => void
  isLoading: boolean
}) => {
  return (
    <>
      <Button
        onClick={onAccept}
        fullWidth
        disabled={isLoading}
        sx={{
          marginTop: Spacings.s,
          fontFamily: 'Inter',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: ' 24px',
          color: ' rgba(255, 255, 255, 1)'
        }}
      >
        {getFigmaText(HardCodedTexts.textGuestParkingStartParking)}
      </Button>

      <MuiButton
        onClick={onDecline}
        variant="text"
        disabled={isLoading}
        sx={{
          color: 'rgba(32, 32, 32, 1)',
          textTransform: 'none',
          textDecoration: 'underline'
        }}
      >
        {getFigmaText(HardCodedTexts.textGuestParkingDecline)}
      </MuiButton>
    </>
  )
}

const GuestParkingConditions = ({ isLoading, onPressContinue, guestParkingState }: GuestParkingViewProps) => {
  const [eulaHTML, setEulaHTML] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const lang = useLanguage()
  const payload = useFreeGuestParkingTokenPayload()

  const { data: site } = useSite(payload?.siteId || '0')

  const promptConditionsTextFirstHalf = `${getFigmaText(HardCodedTexts.textGuestParkingPromptTermsFirstHalf)}`
  const promptConditionsTextSecondHalf = `${getFigmaText(HardCodedTexts.textGuestParkingPromptTermsSecondHalf)}`
  const promptTermsLink = `${getFigmaText(HardCodedTexts.textGuestParkingPromptTermsLink)}`
  const termsAndConditionsHeadline = `${getFigmaText(HardCodedTexts.textGuestParkingTermsAndConditionsHeadline)}`

  function handleDecision(hasApprovedTermsAndConditions: boolean) {
    if (isLoading) {
      return
    }

    return onPressContinue({ ...guestParkingState, hasApprovedTermsAndConditions })
  }

  useEffect(() => {
    getDrifterEulaHTML({ lang }).then((html) => {
      setEulaHTML(html)
    })
  }, [lang])

  return (
    <Layout>
      <Box fullWidth top left right>
        <Box fullWidth align="center">
          <GuestParkingSiteLogo site={site} />
        </Box>

        <Box fullWidth align="center" bottom spacing={Spacings.s}>
          <FigmaText textKey={Texts.textActiveSessionGuestParkingGuestParkingQrLandingHeader} />
        </Box>

        <StyledPromptSection>
          <StyledPromptText>{promptConditionsTextFirstHalf}</StyledPromptText>
          <StyledViewTermsButton onClick={() => setIsModalOpen(true)}>{promptTermsLink}</StyledViewTermsButton>
          <StyledPromptText>{promptConditionsTextSecondHalf}</StyledPromptText>
        </StyledPromptSection>

        <DecisionButtons
          onAccept={() => handleDecision(true)}
          onDecline={() => handleDecision(false)}
          isLoading={isLoading}
        />

        <BottomDrawerModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          headline={termsAndConditionsHeadline}
        >
          <DialogContent sx={{ p: 2, overflowY: 'auto' }}>
            <StyledTermsAndConditionsText dangerouslySetInnerHTML={{ __html: eulaHTML }} />
          </DialogContent>

          <DialogActions
            sx={{
              p: 2,
              borderTop: 1,
              borderColor: 'divider',
              bgcolor: 'background.paper'
            }}
          >
            <Box fullWidth spacing={Spacings.s}>
              <DecisionButtons
                onAccept={() => handleDecision(true)}
                onDecline={() => handleDecision(false)}
                isLoading={isLoading}
              />
            </Box>
          </DialogActions>
        </BottomDrawerModal>
      </Box>
    </Layout>
  )
}

const StyledPromptSection = styled.section``
const StyledPromptText = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: rgba(32, 32, 32, 1);
  margin: 0;
  display: inline;
`

const StyledViewTermsButton = styled.button`
  all: unset;
  text-decoration: underline;
  font-family: Inter;
  color: rgba(177, 29, 148, 1);
  font-size: 14px;
  cursor: pointer;
`

const StyledTermsAndConditionsText = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: rgba(32, 32, 32, 1);
  margin: 0;
  line-height: 1.5;
`

export default GuestParkingConditions
