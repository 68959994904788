import { OffenseType } from '@contracts/types/OffenseType'
import { Site, SitePricing } from '@contracts/types/Site'
import { SiteSessionLogic } from '@contracts/types/SiteSessionLogic'

export const DefaultSiteSessionLogic: Required<SiteSessionLogic> = {
  validRecordCount: 2,
  autoCloseGapTimeMinutes: 60,
  validNationalPlateWeightIncreaseRatio: 3,
  startNewSessionGapTimeSeconds: 60,
  allowRecalculateSessionPaymentOnDemand: false,
  sessionCloseScheduleTimeInMinutes: 48 * 60 // 48h
}

export const DefaultSitePricing: Required<SitePricing> = {
  id: 'default',
  name: 'Default Pricing',
  parking: {
    type: 'absolute',
    items: []
  },
  offense: [
    {
      offenseType: OffenseType.DisabledSlot,
      type: 'absolute',
      items: []
    }
  ],
  overstayOffenseFeeVehicleTypeWhiteList: []
}

export const DefaultSite: Site = {
  id: 'default',
  enablePayments: false,
  enableFossilCarInEVSlotOffenseFee: false,
  enableMarkedSlotOffenseFee: false,
  enableNoPermitInDisabledSlotOffenseFee: false,
  enableQuickStopOffenseFee: false,
  fossilCarInEVSlotOffenseMaxFee: 0,
  markedSlotOffenseMaxFee: 0,
  maxTotalSessionParkingCost: 1000,
  noPermitInDisabledSlotOffenseMaxFee: 0,
  pricing: DefaultSitePricing,
  cameraList: [],
  segments: [],
  sessionLogic: DefaultSiteSessionLogic
}
