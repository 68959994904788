import styled from '@emotion/styled'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'

import { formatPayerAlias } from '@pure/libs/PhoneNumberHelper'

import { Spacings } from '@guest-parking/enums/Spacings'
import { useForm } from '@guest-parking/hooks/useForm'
import { useSiteForWindow } from '@guest-parking/libs/ReactQueryHooks'
import { getFigmaTextFromVariable } from '@guest-parking/libs/TextRepository'
import Texts from '@guest-parking/libs/Texts'
import { Variables } from '@guest-parking/libs/Variables'
import { SignInViewProps } from '@guest-parking/types/SignInTypes'

import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import { GuestParkingSiteLogo } from './GuestParkingSiteLogo'
import { SIGN_IN_BUTTON_ID } from './startSignInWithCode'
import TextFieldForm from './TextFieldForm'

const ERROR_MESSAGE = 'Ange nummer'
const ValidationSchema = Yup.object()
  .shape({
    phone: Yup.string()
      .min(10, ERROR_MESSAGE)
      .test('phone', ERROR_MESSAGE, (text) => formatPayerAlias(text).length > 0)
  })
  .required()

const SignInEnterPhoneNumber = (props: SignInViewProps) => {
  const { isLoading } = props

  const formProps = useForm(ValidationSchema, { defaultValues: { phone: '' } })

  const _onPressContinue = (phone: string) =>
    props.onPressContinue({ ...props.signInState, data: { ...props.signInState.data, phone } })

  const onSubmit = () => {
    if (!formProps.getValues('phone')) return

    const sanitizedPhone = formProps.getValues('phone').replaceAll(' ', '')

    return _onPressContinue(sanitizedPhone)
  }

  const site = useSiteForWindow(window)

  return (
    <Box fullWidth fullHeight align="center" top spacing={Spacings.xxxl}>
      <GuestParkingSiteLogo site={site} />
      <Container fullWidth top spacing={Spacings.xxxl}>
        <FigmaText textKey={Texts.textLoginTxtLoginInputPhoneNumber} />
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <Box fullWidth top bottom spacing={Spacings.s}>
            <Controller
              control={formProps.control}
              name="phone"
              render={({ field, fieldState }) => (
                <TextFieldForm
                  {...field}
                  value={field.value}
                  fullWidth
                  fieldState={fieldState}
                  autoFocus
                  placeholder="07-"
                />
              )}
            />
          </Box>
          <Button
            fullWidth
            text={getFigmaTextFromVariable(Variables['VariableID:362:6744'])}
            type="submit"
            id={SIGN_IN_BUTTON_ID}
            loading={isLoading}
          />
        </form>
      </Container>
    </Box>
  )
}

const Container = styled(Box)`
  max-width: 350px;
`

export default SignInEnterPhoneNumber
