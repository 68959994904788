import { GetParkingSessionOffenseEventData } from '@contracts/types/GetParkingSessionData'
import { ParkingSessionEventOffense } from '@contracts/types/ParkingSession'
import { SiteFeatures, SiteOffenseFees } from '@contracts/types/SiteFeatures'

export const OffenseType = {
  DisabledSlot: 'disabled_slot',
  MarkedSlot: 'marked_slot',
  VehiclePosition: 'vehicle_position',
  QuickstopOverstay: 'quickstop_overstay',
  ChargeSlotOffense: 'charge_slot_offense'
} as const

export type OffenseType = (typeof OffenseType)[keyof typeof OffenseType]

export type OffenseTypeItem = {
  featureKey: keyof SiteFeatures
  maxAmountKey: keyof SiteOffenseFees
  getPartialEvent?: (opts: GetParkingSessionOffenseEventData) => Partial<ParkingSessionEventOffense> | undefined
}
