import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import React from 'react'

import { Spacings } from '@contracts/enums/Spacings'
import { BorderRadiuses } from '@contracts/types/BorderRadixes'

import { Colors } from '@pure/assets/Colors'
import { getDefaultLanguageCode } from '@pure/libs/hooks/useLanguage'

const SubTitle = styled.h3`
  font-family: Montserrat;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: ${Spacings.s};
  color: ${Colors.gray900};
`

const ItemName = styled.h4`
  font-family: Montserrat;
  font-size: 14px;
  margin: 0;
  margin-bottom: ${Spacings.s};
  text-decoration: underline;
  color: ${Colors.gray900};
`

const Text = styled.p`
  font-family: Inter;
  font-size: 14px;
  margin: 0;
  margin-bottom: ${Spacings.xs};
  white-space: pre-line;
  line-height: 1.6;
`

const CustomPricing = ({ site }) => {
  const { items } = site
  const language = getDefaultLanguageCode()

  const pricingTitle = {
    en: 'Prices & Rules',
    sv: 'Priser & Regler'
  }[language]

  return (
    <Box
      sx={{
        p: Spacings.m,
        borderRadius: BorderRadiuses.minimum,
        border: `1px solid ${Colors.gray50}`,
        boxShadow: 'var(--shadow-md)'
      }}
    >
      <SubTitle>{pricingTitle}</SubTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: Spacings.s
        }}
      >
        {items.map((item, itemIndex) =>
          item.text ? (
            <div key={itemIndex}>
              {item.name && <ItemName>{item.name}</ItemName>}
              <Text>{item.text[language]}</Text>
            </div>
          ) : null
        )}
      </Box>
    </Box>
  )
}

export default CustomPricing
