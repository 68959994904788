import { doc, Firestore } from 'firebase/firestore'

import Collections from '@contracts/enums/Collections'
import { CustomPricing } from '@contracts/types/CustomPricing'

import { useFirestoreQuery } from './useFirestoreQuery'

export const useCustomPricing = (db: Firestore, siteId?: string) =>
  useFirestoreQuery<CustomPricing>(doc(db, `${Collections.CUSTOM_PRICING}/${siteId}`), {
    fnName: 'useCustomPricing',
    enabled: Boolean(siteId)
  })
